export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'ActivityIcon',
  },
  {
    title: 'Company',
    route: 'company',
    icon: 'HomeIcon',
  },
  {
    title: 'Trips',
    route: 'trip-list',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Load Board',
    route: 'load-board',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Warehouse',
    route: 'warehouse',
    icon: 'DatabaseIcon',
  },
  {
    title: 'Payments',
    route: 'payment',
    icon: 'FileTextIcon',
  },
  {
    title: 'Invoices',
    route: 'invoice',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Facility',
    icon: 'GitPullRequestIcon',
    children: [
      {
        title: 'Sequences',
        route: 'facility-sequence',
      },
      {
        title: 'Codes',
        route: 'facility-code',
      },
      {
        title: 'Company Facility',
        route: 'company-facility',
      },
    ],
  },
  {
    title: 'Users',
    route: 'user',
    icon: 'UsersIcon',
  },
  {
    title: 'Vendors',
    route: 'vendor',
    icon: 'SmileIcon',
  },
  {
    title: 'Assets',
    route: 'asset',
    icon: 'TruckIcon',
  },
  {
    title: 'Report',
    route: 'report',
    icon: 'EditIcon',
  },
]
